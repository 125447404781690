var _a;
import './css/fonts.scss';
import 'normalize.css';
import './css/common.scss';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'svgxuse';
import React from 'react';
import * as R from 'ramda';
import { createBrowserHistory } from 'history';
import { findCategoryById } from '@kesko/kespro-ui-common';
import { Provider } from 'react-redux';
import { getWindow, getDocument } from '../common/util.js';
import { createStore } from './store.js';
import { createLocationChangeHandler } from './reducers/urlChangeHandler.js';
import { immerUnserializable } from './util/redux.js';
import { authenticationEvent, customPageEvent } from './reducers/analyticsSlice.js';
import { initialState as initialCombinedSearchProductState } from './reducers/combinedSearchProductSlice.js';
import { AppRoot } from './appRoot.js';
import { debounce } from 'lodash-es';
import { LoginEventType } from '../common/apiTypes.js';
import { setCategoryParents } from './util/categories.js';
import { removeNullsFromRecipeFilters } from './util/recipe.js';
import { getRelativeUrlFromLocation } from './util/url.js';
import { analyticsEnvironment } from './config.js';
import { hydrateRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
const win = getWindow();
const doc = getDocument();
const browserHistory = createBrowserHistory();
if (win === undefined || doc === undefined) {
    throw new Error('This file is client-side only, document needs to be defined!');
}
function getInitialRootState() {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
        try {
            const stateText = bodyElement.getAttribute('data-initialRootState');
            bodyElement.removeAttribute('data-initialRootState');
            if (!stateText) {
                return undefined;
            }
            return rehydrateRootState(JSON.parse(stateText));
        }
        catch (e) {
            // If the data cannot be parsed, we'll just ignore it
        }
    }
    return undefined;
}
function updateProductAndCategoryState(rootState) {
    const productState = rootState.products;
    const categoryState = rootState.category;
    const hierarchy = categoryState.hierarchy && R.pipe(setCategoryParents, immerUnserializable)(categoryState.hierarchy);
    const selectedCategory = lift2Undefined(findCategoryById, hierarchy, mapUndefined((cat) => cat.category.id, productState.selectedCategory));
    categoryState.hierarchy = hierarchy;
    productState.selectedCategory = selectedCategory
        ? immerUnserializable(selectedCategory)
        : selectedCategory;
    return rootState;
}
function updateCombinedSearchState(appState) {
    const selectedCategory = lift2Undefined(findCategoryById, appState.category.hierarchy, mapUndefined((cat) => cat.category.id, appState.combinedSearchProduct.selectedCategory));
    const selectedFilters = removeNullsFromRecipeFilters(appState.combinedSearchRecipe.activeFilters);
    return Object.assign(Object.assign({}, appState), { combinedSearchProduct: Object.assign(Object.assign({}, appState.combinedSearchProduct), { selectedCategory: selectedCategory ? immerUnserializable(selectedCategory) : undefined, query: Object.assign(Object.assign({}, appState.combinedSearchProduct.query), { filters: Object.assign(Object.assign({}, initialCombinedSearchProductState.query.filters), appState.combinedSearchProduct.query.filters) }) }), combinedSearchRecipe: Object.assign(Object.assign({}, appState.combinedSearchRecipe), { activeFilters: selectedFilters }) });
}
function mapUndefined(func, value) {
    return value === undefined ? undefined : func(value);
}
function lift2Undefined(func, val1, val2) {
    if (val1 === undefined || val2 === undefined) {
        return undefined;
    }
    return func(val1, val2);
}
function rehydrateRootState(state) {
    return R.pipe(updateProductAndCategoryState, updateCombinedSearchState)(state);
}
function getAppMenuScript(jwt, isMobile) {
    const variable = isMobile ? '_mw' : '_hw';
    const className = isMobile ? 'globalMobileAppMenuContainerClass' : 'globalAppMenuContainerClass';
    const appMenuUrl = process.env.APP_MENU_URL;
    const logOutUrl = process.env.APP_MENU_LOGOUT_URL;
    const styles = `{dropdownPosition: 'bottom-left', dropdownPositionMobile: 'top-right', iconColor: '#fff'}`;
    return `(function (w, d, s, o, f, js, fjs)
        {w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);};
        (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o;
        js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})( window,
        document, 'script', '${variable}', '${appMenuUrl}', );
        ${variable}('init',{element: document.getElementsByClassName('${className}')[0], jwt: '${jwt}', logoutUrl: '${logOutUrl}', styles:${styles}})`;
}
function initializeAppMenu(jwt) {
    const desktopScript = getAppMenuScript(jwt);
    const mobileScript = getAppMenuScript(jwt, true);
    const desktopScriptElement = document.createElement('script');
    desktopScriptElement.innerHTML = desktopScript;
    desktopScriptElement.defer = true;
    const mobileScriptElement = document.createElement('script');
    mobileScriptElement.innerHTML = mobileScript;
    mobileScriptElement.defer = true;
    document.head.appendChild(desktopScriptElement);
    document.head.appendChild(mobileScriptElement);
}
function initializeSSO(reduxStore) {
    const state = reduxStore.getState();
    const { isLoggedIn, justLoggedIn, justLoggedOut, jwt } = state.auth;
    const analyticsData = state.analytics.userAnalyticsData;
    if (isLoggedIn) {
        initializeAppMenu(jwt);
        if (justLoggedIn && analyticsData) {
            authenticationEvent(analyticsData);
        }
    }
    else if (justLoggedOut) {
        authenticationEvent({ loginEventType: LoginEventType.Logout });
    }
}
function initializeGtm() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        environment: analyticsEnvironment,
        originalLocation: document.location.href,
    });
    const script = document.createElement('script');
    script.defer = true;
    script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MHFJ2J8');
            `;
    document.body.appendChild(script);
}
const initialRootState = getInitialRootState();
const store = createStore(initialRootState);
// Fix iOS vh-unit issues
const handleWindowResized = debounce(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}, 100);
window.addEventListener('resize', handleWindowResized);
handleWindowResized();
const appContainer = doc.getElementById('app');
if (appContainer) {
    // If you edit the contents of App remember to edit them in serverSideRender.tsx as well
    try {
        hydrateRoot(appContainer, React.createElement(Provider, { store: store },
            React.createElement(Router, { history: browserHistory },
                React.createElement(AppRoot, null))), {
            onRecoverableError: (err, errorInfo) => {
                var _a;
                // eslint-disable-next-line no-console
                console.error(err, errorInfo.componentStack);
                (_a = window.dtrum) === null || _a === void 0 ? void 0 : _a.reportError(err);
            },
        });
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err, 'Something went wrong when hydrating React root');
        (_a = window.dtrum) === null || _a === void 0 ? void 0 : _a.reportError(err);
    }
}
initializeSSO(store);
if (!process.env.DISABLE_GTM) {
    initializeGtm();
}
const locationChangeHandler = createLocationChangeHandler(store);
browserHistory.listen(locationChangeHandler);
browserHistory.listen((location) => {
    customPageEvent(getRelativeUrlFromLocation(location));
});
const currentLoc = browserHistory.location;
customPageEvent(`${currentLoc.pathname}${currentLoc.search}${currentLoc.hash}`);
