import React from 'react';
import ReactModal from 'react-modal';
import { Icon } from '../../Icon.js';
import styles from './NewsletterSubscription.module.scss';
export const NewsletterSubscriptionModal = ({ isOpen, onClose }) => {
    const handleClose = () => {
        document.body.style.overflow = 'visible';
        onClose();
    };
    const handleAfterOpen = () => {
        document.body.style.overflow = 'hidden';
    };
    if (!isOpen) {
        return null;
    }
    return (React.createElement(ReactModal, { isOpen: isOpen, onRequestClose: handleClose, onAfterOpen: handleAfterOpen, appElement: document.getElementById('app') || undefined, className: styles.modal, style: { overlay: { zIndex: 999 } } },
        React.createElement("button", { "data-pw": "newsletterModalCloseButton", className: styles.modalCloseButton, onClick: handleClose },
            React.createElement("span", null, "Sulje"),
            React.createElement(Icon, { type: "clear", size: 50 })),
        React.createElement("div", { className: styles.modalContainer },
            React.createElement("iframe", { "data-pw": "newsletterIframe", className: styles.iframe, src: `https://assets-eur.mkt.dynamics.com/0ec7a4fa-d07c-49de-af0b-5a4a32ea5b56/digitalassets/standaloneforms/2b946dae-c471-ef11-a670-0022489dad24?ad="${encodeURIComponent(document.location.toString())}` }))));
};
