export const decodeKConsentCookie = (cookie) => {
    if (cookie && cookie.includes('C0002:1')) {
        return {
            ga: true,
        };
    }
    return {
        ga: false,
    };
};
